<template>
  <div>
    <div class="header">
      <div class="header-box">
        <div>新規登録</div>
        <div>
          <span @click="goIndex">ホームページ</span>
          <!-- <span> 問い合わせ</span> -->
        </div>
      </div>
    </div>
    <div class="sign-main">
      <div class="login-info same-style">
        <h4>アカウント情報</h4>
        <div class="form-content">
          <el-form ref="myForm" label-position="left" label-width="175px" :model="loginInfo" :rules="rules">
            <el-form-item label="メールアドレス" prop="Email">
              <el-input placeholder="メールアドレスを入力して下さい" v-model="loginInfo.Email"></el-input>
            </el-form-item>
            <el-form-item label="メールアドレス確認" prop="checkEmail">
              <el-input placeholder="メールアドレスを再度入力下さい" v-model="loginInfo.checkEmail"></el-input>
            </el-form-item>
            <el-form-item label="パスワード" prop="password">
              <el-input placeholder="パスワードを入力して下さい" type="password" v-model="loginInfo.password"></el-input>
            </el-form-item>
            <el-form-item label="パスワード確認" prop="checkPass">
              <el-input placeholder=" パスワードを再度入力して下さい" type="password" v-model="loginInfo.checkPass"></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="user-info same-style">
        <h4>ユーザー情報</h4>
        <div class="userInfo">
          <div class="form-content">
            <el-form label-position="left" label-width="135px" :model="userInfo" :rules="userRules">
              <el-form-item label="ニックネーム" prop="nickname">
                <el-input placeholder="ニックネームを入力して下さい" v-model="userInfo.nickname"></el-input>
              </el-form-item>
              <el-form-item label="アイコン">
                <el-upload class="avatar-uploader" action="no" accept=".jpg, .jpeg, .png" :http-request="upLoadPic"
                  :show-file-list="false">
                  <img v-if="userInfo.imageUrl" :src="userInfo.imageUrl" style="width: 100%" />
                  <i class="el-icon-camera avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
              <el-form-item label="ヘッダー">
                <el-upload class="avatar-uploader" action="no" accept=".jpg, .jpeg, .png" :http-request="upLoadIcon"
                  :show-file-list="false">
                  <img v-if="userInfo.iconUrl" :src="userInfo.iconUrl" style="width: 100%" />
                  <i class="el-icon-camera avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
              <el-form-item label="登録属性" prop="loginAttr">
                <el-radio-group v-model="userInfo.loginAttr">
                  <el-radio label="1">クリエイター </el-radio>
                  <el-radio label="2">編集者</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-form>
          </div>
          <transition name="el-zoom-in-bottom">
            <div class="hope-make">
              <div>
                <el-form label-position="left" label-width="135px" :model="hopeInfo" :rules="hopeRules">
                  <el-form-item label="参加したい工程" prop="checked" v-if="userInfo.loginAttr == 1">
                    <el-checkbox-group v-model="hopeInfo.checked">
                      <el-checkbox @change="filterLang" v-for="(item, index) in this.hopeInfo.type" :key="index"
                        :label="item.id" name="type">{{ item.position }}</el-checkbox>
                    </el-checkbox-group>
                  </el-form-item>
                </el-form>
                <el-form label-position="left" label-width="135px" :model="media" :rules="mediaRules">
                  <el-form-item label="媒体" prop="checked">
                    <el-checkbox-group v-model="media.checked">
                      <el-checkbox v-for="item in media.type" :key="item.id" :label="item.id" name="type">{{ item.media
                      }}</el-checkbox>
                    </el-checkbox-group>
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </transition>
          <div class="classifi">
            <el-form label-position="left" label-width="135px" :model="classification.classA" :rules="classifiA">
              <el-form-item label="ジャンル" prop="checked">
                <el-checkbox-group v-model="classification.classA.checked">
                  <el-checkbox v-for="item in classification.classA.type" :key="item.id" :label="item.id" name="type">{{
                    item.typeb }}</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-form>
          </div>
          <div class="is-creation">
            <el-form label-position="left" label-width="135px" :model="isCreation" :rules="isCreationRules">
              <el-form-item label="創作/エッセイ" prop="type">
                <el-checkbox-group v-model="isCreation.type">
                  <el-checkbox label="1" name="type">創作</el-checkbox>
                  <el-checkbox label="2" name="type">エッセイ</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-form>
          </div>
          <div class="interested-theme">
            <div class="title">
              <h4>興味のある題材</h4>
              <p @click="addInput">
                <span>
                  <i class="el-icon el-icon-plus"></i>
                </span>
                <span>追加</span>
              </p>
            </div>
            <ul>
              <li v-for="(item, index) in bind" :key="index">
                <div>
                  <el-input type="text" placeholder="入力して下さい" v-model="bind[index].name" maxlength="20" show-word-limit>
                  </el-input>
                </div>
                <div @click="deleteInput(index)">削除</div>
              </li>
            </ul>
          </div>
          <!-- <div class="interested-theme">
            <div class="title">
              <h4>タグ</h4>
            </div>
            <div class="ipt-box">
              <el-input
                type="textarea"
                placeholder="入力して下さい"
                v-model="quotation"
                maxlength="60"
                show-word-limit
                :autosize="{ minRows: 3, maxRows: 4 }"
              >
              </el-input>
            </div>
          </div> -->
          <div class="interested-theme">
            <div class="title">
              <h4>自己PR</h4>
            </div>
            <div class="ipt-box">
              <el-input type="textarea" placeholder="入力して下さい" v-model="selfIntroduction" maxlength="60" show-word-limit
                :autosize="{ minRows: 3, maxRows: 4 }">
              </el-input>
            </div>
          </div>
          <div class="interested-theme">
            <div class="title">
              <h4>好きな漫画</h4>
            </div>
            <div class="ipt-box">
              <el-input type="textarea" placeholder="入力して下さい" v-model="cartoon" maxlength="60" show-word-limit
                :autosize="{ minRows: 3, maxRows: 4 }">
              </el-input>
            </div>
          </div>
          <div class="interested-theme">
            <div class="title">
              <h4>好きな作家</h4>
            </div>
            <div class="ipt-box">
              <el-input type="textarea" placeholder="入力して下さい" v-model="favoritePainter" maxlength="60" show-word-limit
                :autosize="{ minRows: 3, maxRows: 4 }">
              </el-input>
            </div>
          </div>
          <div class="interested-theme">
            <div class="title">
              <h4>萌え</h4>
            </div>
            <div class="ipt-box">
              <el-input type="textarea" placeholder="入力して下さい" v-model="point" maxlength="60" show-word-limit
                :autosize="{ minRows: 3, maxRows: 4 }">
              </el-input>
            </div>
          </div>
          <div class="personal-situation" v-if="userInfo.loginAttr == '1'">
            <el-form label-position="left" label-width="135px" :model="perSituation" :rules="methodRules">
              <el-form-item label="作画方法" prop="method_">
                <el-radio-group v-model="perSituation.method_">
                  <el-radio label="1">アナログ</el-radio>
                  <el-radio label="2">デジタル</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-form>
          </div>
          <div class="interested-theme" v-if="userInfo.loginAttr == '1'">
            <div class="title">
              <h4>受賞歴</h4>
              <p @click="addRewardInput">
                <span>
                  <i class="el-icon el-icon-plus"></i>
                </span>
                <span>追加</span>
              </p>
            </div>
            <ul>
              <li v-for="(item, index) in getReward" :key="index">
                <div>
                  <el-input type="text" placeholder="入力して下さい" v-model="getReward[index].name" maxlength="20"
                    show-word-limit>
                  </el-input>
                </div>
                <div @click="deleteRewardInput(index)">削除</div>
              </li>
            </ul>
          </div>
          <div class="personal-situation" v-if="userInfo.loginAttr == '1'">
            <el-form label-position="left" label-width="135px" :model="exper">
              <el-form-item label="漫画経験" prop="method">
                <el-checkbox-group v-model="exper.type">
                  <el-checkbox label="1" name="type">アシスタント</el-checkbox>
                  <el-checkbox label="2" name="type">雑誌掲載</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-form>
          </div>
          <div class="achievements" v-if="userInfo.loginAttr == '1'">
            <div class="title">
              <h4>製作実績</h4>
              <p @click="addResSel">
                <span>
                  <i class="el-icon el-icon-plus"></i>
                </span>
                <span>追加</span>
              </p>
            </div>
            <div>
              <ul>
                <li v-for="(i, index) in achievObj" :key="index">
                  <div class="select-box">
                    <div>
                      <el-row :gutter="20">
                        <el-col :span="8">
                          <div>
                            <el-select size="medium" v-model="i.selfProduVal" placeholder="選択して下さい">
                              <el-option v-for="item in i.selfProdu" :key="item.value" :label="item.label"
                                :value="item.value">
                              </el-option>
                            </el-select>
                          </div>
                        </el-col>
                        <el-col :span="8">
                          <div>
                            <el-select size="medium" v-model="i.colorVal" placeholder="選択して下さい">
                              <el-option v-for="item in i.color" :key="item.value" :label="item.label"
                                :value="item.value">
                              </el-option>
                            </el-select>
                          </div>
                        </el-col>
                        <el-col :span="8">
                          <div>
                            <el-select size="medium" v-model="i.isSerialVal" placeholder="選択して下さい">
                              <el-option v-for="item in i.isSerial" :key="item.value" :label="item.label"
                                :value="item.value">
                              </el-option>
                            </el-select>
                          </div>
                        </el-col>
                      </el-row>
                    </div>
                    <div>
                      <el-row :gutter="20">
                        <el-col :span="8">
                          <div>
                            <el-select size="medium" v-model="i.serialSizeVal" placeholder="選択して下さい">
                              <el-option v-for="item in i.serialSize" :key="item.value" :label="item.label"
                                :value="item.value">
                              </el-option>
                            </el-select>
                          </div>
                        </el-col>
                        <el-col :span="8">
                          <div>
                            <el-input type="text" placeholder="作品タイトル" v-model="i.workName" maxlength="20"
                              show-word-limit>
                            </el-input>
                          </div>
                        </el-col>
                        <el-col :span="8">
                          <div>
                            <el-input type="text" placeholder="ページ数" v-model="i.author" maxlength="20" show-word-limit>
                            </el-input>
                          </div>
                        </el-col>
                      </el-row>
                    </div>
                    <div>
                      <el-input type="text" placeholder="コメント" v-model="i.desc" maxlength="30" show-word-limit>
                      </el-input>
                    </div>
                  </div>
                  <div class="remove" @click="deleteResSel(index)">削除</div>
                </li>
              </ul>
            </div>
          </div>
          <div class="interested-theme" v-if="userInfo.loginAttr == '1'">
            <div class="title">
              <h4>ポートフォリオ</h4>
              <p @click="addUrlInput">
                <!-- <span>
                  <i class="el-icon el-icon-plus"></i>
                </span> -->
                <!-- <span>追加</span> -->
              </p>
            </div>
            <ul>
              <li v-for="(item, index) in resUrl" :key="index">
                <div>
                  <el-input type="text" placeholder=" 入力して下さい" v-model="resUrl[index].name" maxlength="30"
                    show-word-limit>
                  </el-input>
                </div>
                <div @click="deleteUrlInput(index)"></div>
              </li>
            </ul>
          </div>
          <div class="personal-situation">
            <el-form label-position="left" label-width="150px" :model="makeAndEdit">
              <el-form-item label="広告マンガ製作依頼" prop="method">
                <el-radio-group v-model="makeAndEdit.make">
                  <el-radio label="1">受ける</el-radio>
                  <el-radio label="2">受けない</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="所属編集部" v-if="userInfo.loginAttr == '2'">
                <el-radio-group v-model="makeAndEdit.edit">
                  <el-radio label="1">出版社編集部所属</el-radio>
                  <el-radio label="2">フリーランス</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-form>
          </div>
          <div class="achievements" v-if="userInfo.loginAttr == '2'">
            <div class="title">
              <h4>製作実績</h4>
              <p @click="addResSel">
                <span>
                  <i class="el-icon el-icon-plus"></i>
                </span>
                <span>追加</span>
              </p>
            </div>
            <div>
              <ul>
                <li v-for="(i, index) in achievObj" :key="index">
                  <div class="select-box">
                    <div>
                      <el-row :gutter="20">
                        <el-col :span="8">
                          <div>
                            <el-select size="medium" v-model="i.selfProduVal" placeholder="選択して下さい">
                              <el-option v-for="item in i.selfProdu" :key="item.value" :label="item.label"
                                :value="item.value">
                              </el-option>
                            </el-select>
                          </div>
                        </el-col>
                        <el-col :span="8">
                          <div>
                            <el-select size="medium" v-model="i.colorVal" placeholder="選択して下さい">
                              <el-option v-for="item in i.color" :key="item.value" :label="item.label"
                                :value="item.value">
                              </el-option>
                            </el-select>
                          </div>
                        </el-col>
                        <el-col :span="8">
                          <div>
                            <el-select size="medium" v-model="i.isSerialVal" placeholder="選択して下さい">
                              <el-option v-for="item in i.isSerial" :key="item.value" :label="item.label"
                                :value="item.value">
                              </el-option>
                            </el-select>
                          </div>
                        </el-col>
                      </el-row>
                    </div>
                    <div>
                      <el-row :gutter="20">
                        <el-col :span="8">
                          <div>
                            <el-select size="medium" v-model="i.serialSizeVal" placeholder="選択して下さい">
                              <el-option v-for="item in i.serialSize" :key="item.value" :label="item.label"
                                :value="item.value">
                              </el-option>
                            </el-select>
                          </div>
                        </el-col>
                        <el-col :span="8">
                          <div>
                            <el-input type="text" placeholder="作品タイトル" v-model="i.workName" maxlength="20"
                              show-word-limit>
                            </el-input>
                          </div>
                        </el-col>
                        <el-col :span="8">
                          <div>
                            <el-input type="number" placeholder="ページ数" v-model="i.author">
                            </el-input>
                          </div>
                        </el-col>
                      </el-row>
                    </div>
                    <div>
                      <el-input type="text" placeholder="コメント" v-model="i.desc" maxlength="30" show-word-limit>
                      </el-input>
                    </div>
                  </div>
                  <div class="remove" @click="deleteResSel(index)">削除</div>
                </li>
              </ul>
            </div>
          </div>
          <div class="personal-situation">
            <el-form label-position="left" label-width="135px" :model="makeAndEdit">
              <el-form-item label="海外への出版" prop="method">
                <el-radio-group v-model="makeAndEdit.publish">
                  <el-radio label="1">希望する</el-radio>
                  <el-radio label="2">希望しない</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-form>
          </div>
          <div class="interested-theme" v-if="userInfo.loginAttr == '1' && islang" :key="num">
            <div class="title">
              <h4>翻訳可能言語</h4>
              <p @click="addLangSel">
                <span>
                  <i class="el-icon el-icon-plus"></i>
                </span>
                <span>追加</span>
              </p>
            </div>
            <ol>
              <li v-for="(i, index) in language" :key="index">
                <div>
                  <el-row :gutter="20">
                    <el-col :span="8">
                      <div>
                        <el-select size="medium" v-model="i.fromLanguageVal" placeholder="選択して下さい">
                          <el-option v-for="item in i.lang" :key="item.id" :label="item.language" :value="item.id">
                          </el-option>
                        </el-select>
                      </div>
                    </el-col>
                    <el-col :span="8">
                      <div>
                        <label for="">翻訳</label>
                      </div>
                    </el-col>
                    <el-col :span="8">
                      <div>
                        <el-select size="medium" v-model="i.toLanguageVal" placeholder="選択して下さい">
                          <el-option v-for="item in i.lang" :key="item.id" :label="item.language" :value="item.id">
                          </el-option>
                        </el-select>
                      </div>
                    </el-col>
                  </el-row>
                </div>
                <div @click="deleteLang(index)">削除</div>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div class="notice same-style">
        <el-checkbox v-model="notice" style="margin:10px"></el-checkbox>
        <el-link @click="clickRhxz" type="primary" :underline="true">利用規約</el-link>
        <span>及び</span>
        <el-link @click="clickRhjs" type="primary" :underline="true">プライバシーポリシー</el-link>
        <span>に同意する</span>
      </div>
      <div class="submit same-style">
        <span @click="isOpenDrawe">確認画面</span>
      </div>
    </div>
    <!-- 抽屉 -->
    <el-drawer title="登録情報" :visible.sync="drawer" direction="rtl" size="50%">
      <div class="registerInfo">
        <el-descriptions :label-style="LS" title="ユーザ情報" :column="1" style="margin-top:20px" border>
          <el-descriptions-item label-class-name="my-label" style="width:200px" label="アイコン"
            v-if="userInfo.imageUrl"><el-avatar :size="80" :src="userInfo.imageUrl"></el-avatar> </el-descriptions-item>
          <el-descriptions-item label-class-name="my-label" style="width:200px" label="ヘッダー"
            v-if="userInfo.iconUrl"><el-avatar :size="80" :src="userInfo.iconUrl"></el-avatar> </el-descriptions-item>
          <el-descriptions-item label-class-name="my-label" style="width:200px" label="ユーザ名">{{ loginInfo.Email
          }}</el-descriptions-item>
          <el-descriptions-item label-class-name="my-label" label="ニックネーム">{{ userInfo.nickname }} </el-descriptions-item>
          <el-descriptions-item label-class-name="my-label" label="登録属性">{{ userInfo.loginAttr == 1 ? 'クリエイター ' :
            '編集者' }}</el-descriptions-item>
          <el-descriptions-item label-class-name="my-label" v-if="userInfo.loginAttr == 1" label="参加したい工程"
            style="width:100px">
            <span style="padding: 0 5px" v-for="(item, index) in hopeInfo.checked">{{ filterHopeType(item) }}</span>
          </el-descriptions-item>
          <el-descriptions-item label-class-name="my-label" label="媒体" v-if="media.checked.length">
            <span style="padding: 0 5px" v-for="(item, index) in media.checked">{{ filterMediaType(item) }}</span>
          </el-descriptions-item>
          <el-descriptions-item label-class-name="my-label" label="ジャンル">
            <span style="padding: 0 5px" v-for="(item, index) in classification.classA.checked">{{ filterClassAType(item)
            }}</span>
          </el-descriptions-item>
          <el-descriptions-item label-class-name="my-label" label="創作/エッセイ">
            <template v-if="isCreation.type.length > 1">
              <span style="margin-left:15px">創作</span>
              <span style="margin-left:15px">エッセイ</span>
            </template>
            <template v-else-if="isCreation.type.length == 1">
              <span style="margin-left:15px" v-if="isCreation.type[0] == 1">創作</span>
              <span style="margin-left:15px" v-else>エッセイ</span>
            </template>
          </el-descriptions-item>
          <el-descriptions-item label-class-name="my-label" v-if="bind.length && bind[0].name" label="興味のある題材">
            <p v-for="(item, index) in bind" :key="index">{{ item.name }}</p>
          </el-descriptions-item>
          <el-descriptions-item label-class-name="my-label" v-if="selfIntroduction" label="自己PR">
            <p>{{ selfIntroduction }}</p>
          </el-descriptions-item>
          <el-descriptions-item label-class-name="my-label" v-if="cartoon" label="好きな漫画">
            <p>{{ cartoon }}</p>
          </el-descriptions-item>
          <el-descriptions-item label-class-name="my-label" v-if="favoritePainter" label="好きな作家">
            <p>{{ favoritePainter }}</p>
          </el-descriptions-item>
          <el-descriptions-item label-class-name="my-label" v-if="point" label="萌え">
            <p>{{ point }}</p>
          </el-descriptions-item>
          <el-descriptions-item label-class-name="my-label" v-if="perSituation.method_" label="作画方法">
            <p>{{ perSituation == 1 ? "アナログ" : "デジタル" }}</p>
          </el-descriptions-item>
          <el-descriptions-item label-class-name="my-label" v-if="getReward.length && getReward[0].name" label="受賞歴">
            <p v-for="(item, index) in getReward" :key="index">{{ item.name }}</p>
          </el-descriptions-item>
          <el-descriptions-item label-class-name="my-label" v-if="exper.type.length" label="漫画経験">
            <template v-if="exper.type.length > 1">
              <span style="margin-left:15px">アシスタント</span>
              <span style="margin-left:15px">雑誌掲載</span>
            </template>
            <template v-else-if="exper.type.length == 1">
              <span v-if="exper.type[0] == 1">アシスタント</span>
              <span style="margin-left:15px" v-else>雑誌掲載</span>
            </template>
          </el-descriptions-item>
          <el-descriptions-item label-class-name="my-label"
            v-if="achievObj.length && (achievObj[0].selfProduVal || achievObj[0].colorVal)" label="製作実績">
            <div style="margin-bottom: 10px" class="achive_" v-for="(item, index) in achievObj">
              <span v-if="item.selfProduVal" style="padding: 0 10px 0 0">{{ item.selfProduVal == '1' ? '商業' :
                '自主制作' }}</span>
              <span v-if="item.colorVal" style="padding: 0 10px 0 0">{{ item.colorVal == '1' ? 'カラー' : '白黒' }} </span>
              <span v-if="item.isSerialVal" style="padding: 0 10px 0 0">{{ item.isSerialVal == '1' ? '読切' : '連載' }}</span>
              <span v-if="item.serialSizeVal" style="padding: 0 10px 0 0">{{ item.serialSizeVal == '1' ? '縦スク' : '見開き'
              }}</span>
              <p style="padding: 0 10px 0 0" v-if="item.workName">{{ item.workName }}</p>
              <p style="padding: 0 10px 0 0" v-if="item.author">{{ item.author }}</p>
              <p style="padding: 0 10px 0 0" v-if="item.desc">{{ item.desc }}</p>
            </div>
          </el-descriptions-item>
          <el-descriptions-item label-class-name="my-label" v-if="resUrl[0].name" label="ポートフォリオ">
            <p>{{ resUrl[0].name }}</p>
          </el-descriptions-item>
          <el-descriptions-item label-class-name="my-label" v-if="makeAndEdit.make" label="広告マンガ製作依頼">{{ makeAndEdit.make
            == 1 ? '受ける ' : '受けない' }}</el-descriptions-item>
          <el-descriptions-item label-class-name="my-label" v-if="makeAndEdit.edit" label="所属編集部">{{ makeAndEdit.edit == 1
            ? '出版社編集部所属 ' : 'フリーランス' }}</el-descriptions-item>
          <el-descriptions-item label-class-name="my-label" v-if="makeAndEdit.publish" label="海外への出版">{{
            makeAndEdit.publish == 1 ? '希望する ' : '希望しない' }}</el-descriptions-item>
          <el-descriptions-item label-class-name="my-label" v-if="islang && language.length" label="翻訳可能言語">
            <div v-for="(item, index) in language">
              <span>{{ getLanguage(item.fromLanguageVal) }}</span>
              <span style="padding: 0 20px">翻訳先</span>
              <span>{{ getLanguage(item.toLanguageVal) }} </span>
            </div>
          </el-descriptions-item>
        </el-descriptions>
      </div>
      <div style="margin-left:15px">
        <el-button @click="drawer = false">取　消</el-button>
        <el-button type="primary" @click="submit">確　定</el-button>
      </div>
    </el-drawer>
    <!-- 入会规则 -->
    <el-dialog title="漫画共同制作システム利用規約" :visible.sync="dialogVisibleOne" width="70%" top="10vh" lock-scroll>
      <div class="dialong-text">
        <div ref="dialogtopOne" style="max-height: 60vh; overflow-y: scroll; padding:0 20px" @scroll="handleScrollOne">
          <h3>第 1 条 本システムの内容</h3>
          <div class="indent">
            本システムは、漫画の共同制作システムです（以下「本システム」といいます。
          </div>
          <div class="indent">
            また「本システム」を通じて提供されるサービスを「本サービス」といいます。）。
          </div>
          <div class="indent">
            本システムは、漫画共同システムに利用登録いただいた方（以下「ユーザー」といいます。）の間で編集者と原作者等のマッチングを行って、
          </div>
          <div class="indent">
            これらの方のプロジェクトへのマッチングを実施し、ユーザーが漫画共同制作を行うためのシステムを提供するサービスです。
          </div>
          <div class="indent">
            ユーザー間で漫画共同制作に必要な環境を設定するものであり、ユーザー間の契約、ユーザーの作業、承認、
          </div>
          <div class="indent">
            その他漫画共同制作に必要な各行為に関しては、
          </div>
          <div class="indent">
            すべて当事者であるユーザーの自己責任とします。当社は制作・配信を行うものではなく、
          </div>
          <div class="indent">
            ユーザーから制作や配信の委託を受けるものでもありません。
          </div>
          <div class="indent">
            当社は、当該プロジェクトに参加する全ユーザーの同意に基づきユーザーと配信先の配信代行を行うため、
          </div>
          <div class="indent">
            ユーザー及び配信先との関連するの契約を締結するものとします。
          </div>
          <div class="indent">
            但し、配信にかかるライセンス料の分配のためのプラットフォームの提供を行いますが、
          </div>
          <div class="indent">
            直接ユーザー間の分配契約自体の当事者となるものではありません。
          </div>
          <div class="indent">
            本システムの内容は、本利用規約及びガイドに規定する通りとします。
          </div>
          <h3>第2条　漫画共同制作フロー概略</h3>
          <p class="small-title">（1） マッチング</p>
          <div class="indent">
            　ユーザー登録後、編集者と漫画家のマッチング機能が使用可能となる。
          </div>
          <div class="indent">
            　マッチング機能には、システムマッチングと手動マッチングがあり、 登録属性によりシステムにてマッチング度合が計算される。
          </div>
          <div class="indent">
            　ユーザーは、気になる他のユーザーがいれば、他のユーザーに対してマッチング申請を行う。
          </div>
          <div class="indent">
            　マッチング申請を受けた他のユーザーは、マッチング申請に対して、申請許可か拒否を行う。
          </div>
          <div class="indent">
            　当該ユーザー間でマッチングが成立すれば、お互いにパートナーとして相手方ユーザーに登録され、
          </div>
          <div class="indent">
            　友達リストに加えられることとなる。
          </div>
          <p class="small-title">
            （2） プロジェクト
          </p>
          <div class="indent">
            　原作者は必要な情報を登録し、プロジェクト（漫画製作）を立ち上げる。
          </div>
          <p class="small-title">
            （3） 企画
          </p>
          <div class="indent">
            　原作者がプロジェクトを立ち上げた際、まず企画情報が作成される。企画ではネームまでの事前準備作業を行う。
          </div>
          <div class="indent">
            　商業制作であれば、編集者が必要なので、作業を行う前に、編集者をプロジェクトに招待する。
          </div>
          <div class="indent">
            　自分の友達リストに存在する編集者を招待し、当該編集者が同意すれば、編集者がプロジェクトに参加する。
          </div>
          <div class="indent">
            　原作者は企画を勧めていくうえで、必要なポジションにメンバー（進捗マネージャー、キャラクターデザイナー、
          </div>
          <div class="indent">
            　背景デザイナー、ネームライター、メイン作画）を募集する。
          </div>
          <div class="indent">
            　募集情報や報酬情報（時給制、仮配当率）などを登録して、募集を開始する。
          </div>
          <div class="indent">
            　また、マッチング機能において、募集情報に基づき上流アシスタント（進捗マネージャー、
          </div>
          <div class="indent">
            　キャラクターデザイナー、背景デザイナー、ネームライター、メイン作画）に対して推薦する。
          </div>
          <div class="indent">
            　応募者は薦められたプロジェクトが気に入れば、応募することができる。
          </div>
          <div class="indent">
            　原作者は応募者情報を確認し、参加メンバーを確定させる。募集および応募が完了したら、
          </div>
          <div class="indent">
           　電子契約を締結し、実際の作業に着手する。
          </div>
          <p class="small-title">
            （4） 案件（話）</p>
          <div class="indent">
            　原作者は案件（話）情報を登録する。当話のネームが完了したら、ネーム担当者は編集者に承認依頼を行う。
          </div>
          <div class="indent">
            　編集者が承認すると、ネームが完了する（承認されなかった場合、編集者のコメントに基づいて修正して再度承認依頼を行う）。
          </div>
          <p class="small-title">
            （5） ネーム完了後のフロー
          </p>
          <div class="indent">
            　原作者は案件（話）における必要なポジションにメンバー（背景アシスタント、ベタ・トーンアシスタント、
          </div>
          <div class="indent">
            　着彩（カラー）アシスタント、モブアシスタント、効果アシスタント 、写植アシスタント 、その他アシスタント）を募集する。
          </div>
          <div class="indent">
            　募集情報や報酬情報（時給制、配当率）などを登録して、募集を開始する。システム上の募集情報に基づいて、アシスタント
          </div>
          <div class="indent">
            　（背景アシスタント、ベタ・トーンアシスタント、着彩（カラー）アシスタント、モブアシスタント、効果アシスタント、
          </div>
          <div class="indent">
            　写植アシスタント 、その他アシスタント）を募集する。
            </div>
           <div class="indent">
            　応募者が薦められた案件（話）が気に入れば、応募する。
          </div>
          <div class="indent">
            　原作者は応募者情報を確認し、オファーを決める。募集が完了したら、電子契約を締結する。
          </div>
          <div class="indent">
            　そして、原作者はページ毎の作業フロー及び作業担当者を設定する。設定が完了したら、作業開始する。
          </div>
          <p class="small-title">
            （6） 制作
          </p>
          <div class="indent">
            　設定されたフローに基づいて、作業担当者が作業を行う。作業完了後、システムにアップロードして、
          </div>
          <div class="indent">
            　承認者（メイン作画）に承認依頼を行う。
          </div>
          <div class="indent">
            　この時、該当ページがロックされて、変更できない状態になる。承認者はアップロードされたページを確認して、
          </div>
          <div class="indent">
            　問題なければ、承認する。問題があれば、コメント等記載して、否決する。
          </div>
          <div class="indent">
            　否決されたら、作業担当者はコメントに基づいて修正し、再度アップロードして承認依頼を行う。
          </div>
          <div class="indent">
            　承認されたら、次の作業担当者に通知し、次の工程へと進める。この作業手順で該当ページの作業を完了させる。
          </div>
          <p class="small-title">
            （7） 校了承認
          </p>
          <div class="indent">
            　当案件（話）の全てのページ作業が完成したら、編集者に校了依頼を行う。
          </div>
          <div class="indent">
            　編集者が校了承認すると、配信可能な状態となる。
          </div>
          <h3>第 3 条 本規約への同意・変更</h3>
          <p class="small-title">
            1. 本規約への同意及び適用</p>
          <div class="indent">
            　本規約は、本システムの利用に関する権利義務関係・条件をユーザーと当社との間で定めることを目的とし、他の規約又は契約において特段の規定のない限り、</div>
          <div class="indent">
            　ユーザーと当社の間の本システムの利用に関わる一切の関係に適用されます。ユーザーは、本規約に同意をしたうえで、</div>
          <div class="indent">
            　本規約の定めに従って本システムを利用するものとし、ユーザーは、本システムを利用することにより本規約に同意をしたものとみなされます。</div>
          <p class="small-title">
            2. 未成年者の場合</p>
          <div class="indent">
            　ユーザーが未成年者である場合は、事前に親権者など法定代理人の包括的な同意を得たうえで本システムを利用しなければなりません。</div>
          <div class="indent">
            　ユーザーが未成年者である場合は、法定代理人の同意の有無に関して、当社からユーザー又は法定代理人に対し、確認の連絡をする場合があります。</div>
          <div class="indent">
            　また、未成年者が利用権限又は契約締結権限があると偽って利用登録又は契約締結を行った場合に、当社又は当社のユーザーが損害を被った場合には、</div>
          <div class="indent">
            　当該未成年者又は監督義務者に対して法令に従い損害賠償請求等を行う可能性があります。</div>
          <p class="small-title">
            3. 本規約の変更</p>
          <div class="indent">
            　当社は、必要に応じ、本システム内の適宜の場所に掲示して、本規約の内容を任意にかつ随時に変更できるものとします。本規約の当該変更後に、</div>
          <div class="indent">
            　ユーザーが本システムを利用した場合には、ユーザーは、本規約の変更に同意をしたものとみなされます。もしも、本規約の変更に同意しない場合には、</div>
          <div class="indent">
            　当該ユーザーは、本システムの利用を停止する手続を行ってください。</div>
          <div class="indent">
            　当社は、本規約の変更によりユーザーに生じたいかなる損害についても責任を負わないものとします</div>
          <div class="indent">  
           　（故意又は重過失の場合には法令に従って処理されるものとします）。</div>

          <h3>第 4 条 ユーザー登録及びアカウント情報</h3>
          <p class="small-title">
            1. 登録</p>
          <div class="indent">
            　ユーザー登録を行う方は、必ず本人が行うものとし、真実かつ正確な情報を入力するものとします。なお、ユーザー登録後のユーザーにおいても、</div>
          <div class="indent">
            　本システムを利用する際の入力については、真実かつ正確な情報入力を行うものとします。</div>
          <div class="indent">
            　当社又は当社の他のユーザーが、真実かつ正確でない入力情報に基づいて行動した結果、当社又は当社の他のユーザーが損害を被った場合には、</div>
          <div class="indent">
            　当該情報の入力を行った方は、当社又は他のユーザーが被った損害を賠償する責任を負うものとします。</div>
          <p class="small-title">
            2. 複数登録の禁止</p>
          <div class="indent">
            　ユーザー登録は、一人一登録とし、複数のユーザー登録を行うことができないものとします。</div>
          <div class="indent">
            　複数のユーザー登録がなされている場合には、当社は、その判断により、当該複数のユーザー登録の全て又は一部の抹消を行い、</div>
          <div class="indent">
            　また複数登録により損害を被った場合には当該損害の賠償を請求できるものとします。なお、当該ユーザー登録の抹消がなされた場合であっても、</div>
          <div class="indent">
            　当社は、当該複数ユーザー登録を行った者に対して損害賠償義務その他いかなる責任も負わないものとします。</div>
          <p class="small-title">
            3. 登録拒否</p>
          <div class="indent">
            　当社は、ユーザー登録をされようとする方が以下各号のいずれかに該当する場合、ユーザー登録の申請を承認しないことがあります。</div>
          <p class="small">（１）本約款で規定するユーザー資格を満たしていない場合</p>
          <p class="small">（２）過去に本規約違反等により、当社から利用停止等の処分を受けている場合</p>
          <p class="small">（３）登録内容に真実でない情報又は正確ではない情報が含まれている場合</p>
          <p class="small">
            （４）本システムの運営、本サービス提供又は他のユーザーの利用を妨害・阻害する行為を行った場合やそのおそれがあると当社が合理的に判断した場合
          </p>
          <P class="small">
            <div class="small">
            （５）暴力団、暴力団員、暴力団員でなくなったときから5年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、
            </div>
                 <div class="indent">
                   　　社会運動等標ぼうゴロ若しくは特殊知能暴力集団、その他これらに準ずる者（以下これらを「暴力団員等」といいます。）、
                 </div>
                 <div class="indent">
                   　　テロリスト等日本政府若しくは外国政府が経済制裁の対象として指定する者に該当すること、
                 </div>
                 <div class="indent">
                   　　又は暴力団員等と一定の関係を有すること（暴力団員等に対して資金を提供し若しくは便宜を供与するなどの関与をしていると認められること、
                 </div>
                 <div class="indent">
                  　　暴力団員等を不当に利用していると認められること、又は、ユーザー登録をされようとする方が法人の場合、</div>
                 <div class="indent">
                  　　暴力団員等がその経営を支配し若しくはその法人の経営に実質的に関与していると認められること、</div>
                 <div class="indent">
                  　　その他暴力団員等と社会的に非難されるべき関係を有していると認められること）</div>
                 <div class="indent">
                  　　（以下、上記のすべてを総称して「反社会的勢力」といいます。）が判明した場合</div>
          </p>
          （6）国内外の法令の抵触がある場合その他当社が不適当であると合理的な理由に基づき判断する場合
          <p class="small-title">
            4. ユーザー等の確約</P>
          <div class="indent">
            　ユーザー等は、反社会的勢力のいずれにも該当しないこと、かつ将来にわたっても該当しないこと、及び、自ら又は第三者を利用して、</div>
          <div class="indent"> 
            　暴力的な要求行為、法的な責任を超えた不当な要求行為、取引に関して脅迫的な言動をし又は暴力を用いる行為、風説を流布し、</div>
          <div class="indent">
            　偽計を用い又は威力を用いて当社の信用を毀損し又は当社の業務を妨害する行為、その他これらに準ずる行為を行わないことを確約するものとします。</div>
          <p class="small-title">
            5. 登録内容の変更</p>
          <div class="indent">
            　ユーザーは、登録内容に変更がある場合は、直ちに変更しなければならず、常にユーザー自身の正確な情報が登録されているように登録内容を管理及び</div>
            <div class="indent">
            　修正する責任を負います。登録内容に変更があったにも関わらず、変更を行っていない場合、</div>
            <div class="indent">
            　当社は、登録内容に変更がないものとして取り扱うことができます。</div>
          <div class="indent">
            　変更の届出があった場合でも、変更登録前に行われた取引や各種手続は、変更前の情報に依拠する場合があります。</div>
          <p class="small-title">
            6. アカウント情報の管理</P>
          <div class="indent">
            　ユーザーは、入力したメールアドレス、パスワード等の情報（以下「アカウント情報」といいます。）を自ら責任をもって管理するものとします。</div>
          <div class="indent">
            　ユーザーは、アカウント情報を自己以外の第三者に利用させてはならず、譲渡、売買、質入、貸与、賃貸、</div>
            <div class="indent">
            　担保設定その他形態を問わず処分することはできないものとします。</div>
          <p class="small-title">
            7. アカウント情報の不正利用等</P>
          <div class="indent">
            　アカウント情報の管理不十分による情報の漏洩、使用上の過誤、第三者の使用、不正アクセス等による損害の責任はユーザーが負うものとし、</div>
          <div class="indent">
            　当社の故意又は重過失に起因する場合を除き、当社は責任を負わないものとします。</div>
          <div class="indent">
            　また、アカウント情報が不正に利用されたことにより当社に損害が生じた場合、ユーザーは当該損害を賠償するものとします。</div>
          <p class="small-title">
            8. アカウント情報の漏えい</P>
          <div class="indent">
            　ユーザーは、アカウント情報が第三者に漏えいした場合又はそのおそれがある場合、速やかに当社まで連絡するものとします。</div>
          <div class="indent">
            　また、その際に当社の指示がある場合にはこれに従うものとします。</div>
          <h3>第 5 条 ユーザー登録の取消等</h3>
          <p class="small-title">
            1. ユーザー登録の取消・利用停止等</P>
          <div class="indent">
            　当社は、ユーザーが以下の各号のいずれかに該当した場合又は該当したと当社が合理的な理由に基づき判断した場合、</div>
          <div class="indent">
            　事前の通知なしに、ユーザー登録の取消、本システムの全部若しくは一部へのアクセスの拒否、利用停止等、</div>
          <div class="indent">
            　又は、ユーザーに関連するコンテンツや情報の全部若しくは一部の削除の措置をとることができるものとし、</div>
          <div class="indent">
            　当社は、その理由を説明する義務を負わないものとします。なお、当社は、ユーザーが以下の各号のいずれにも該当しないことを確認するために、</div>
          <div class="indent">
            　当社が必要と判断する本人確認を行うことができ、かかる本人確認が完了するまで本システムの全部又は一部へのアクセスの拒否、</div>
            <div class="indent">
            　利用停止等の措置をとることができます。</div>

          <p class="small">（１）法令又は本規約に違反した場合</p>
          <p class="small">（２）不正行為があった場合</p>
          <p class="small">（３）登録した情報が虚偽の情報であると当社が判断した場合</p>
          <p class="small">（４）本規約上必要となる手続又は当社への連絡を行わなかった場合</p>
          <p class="small">（５）登録した情報が既存の登録と重複している場合</p>
          <p class="small">（６）登録した携帯電話番号又はメールアドレスが不通になったことが判明した場合</p>
          <p class="small">（７）ユーザーが債務超過、無資力、支払停止又は支払不能の状態に陥った場合</p>
          <p class="small">（８）他のユーザーや第三者に不当に迷惑をかけた場合</p>
          <p class="small">
            （９）ユーザーが登録した金融機関の口座に関し違法、不適切その他の問題があることが当該金融機関による指摘等により判明した場合
          </p>
          <p class="small">（１０）第 4 条第 3 項各号のいずれかに該当する場合</p>
          <div class="indent">
            　　ユーザーが自ら又は第三者をして、暴力的な要求行為、法的な責任を超えた不当な要求行為、脅迫的な言動若しくは暴力を用いる行為、又は風評を流布し、</div>
          <div class="indent">
            　　偽計を用い若しくは威力を用いて、信用を毀損若しくは業務を妨害する行為をした場合、その他当社がユーザーに相応しくないと判断した場合</div>
          <p class="small-title">
            2. 本システムの利用禁止等</P>
          <div class="indent">
            　当社は、本条の措置を受けたユーザーに対し、将来にわたって当社が提供するサービスの利用及びアクセスを禁止することができるものとします。</div>
          <div class="indent">
            　当社は、本条の措置により生じる損害について、当社の故意又は過失に起因する場合を除き、責任を負わないものとします。</div>
          <h3>第 6 条 ユーザーの退会</h3>
          <p class="small-title">
            1. 退会の手続</P>
          <div class="indent">
            　ユーザーは、当社に退会を希望する旨を申し出ることができ、当社の判断により認められた場合には退会することができます。</div>
          <div class="indent">
            　但し、ユーザーが当社又は他のユーザーと締結している契約又は当社との契約（本約款の規定による場合を含む）上、</div>
          <div class="indent">
            　当社又は他のユーザーに義務を負う場合において、当該義務の履行のためにユーザー登録の維持が必要な場合には、</div>
          <div class="indent">
            　退会には当社の書面による承諾を要するものとします。</div>
          <p class="small-title">
            2. 当社の免責</P>
          <div class="indent">
            　当社は、本条の措置により生じる損害について、当社の故意又は過失に起因する場合を除き、責任を負わないものとします</div>
          <h3>第 7 条 個人情報等取扱い</h3>
          <p class="small-title">
            1. プライバシーポリシー</P>
          <div class="indent">
            　当社は、本規約のほか、プライバシーポリシーに従って個人情報を取り扱います。</div>
          <p class="small-title">
            2. プライバシーポリシーへの同意</P>
          <div class="indent">
            　ユーザーは、本システムの利用又は本システムの閲覧の前に、本システム上で、プライバシーポリシーを必ず確認し、その内容に同意した上で、</div>
            <div class="indent"> 
            　本システムを利用するものとします。本システムの利用がなされている場合には、プライバシーポリシーへの同意があったものとみなされるものとします。</div>
          <p class="small-title">
            3. ユーザーによる利用</P>
          <div class="indent">
            　ユーザーは、本システムを通じて得た個人情報等に関し、本システムの利用の範囲内においてのみ利用することができ、</div>
          <div class="indent"> 
            　それ以外の利用はできないものとします。</div>
          <div class="indent">
            　あるユーザーの個人情報の利用は、当該ユーザーがプライバシーポリシーに承諾することにより、</div>
          <div class="indent"> 
            　他のユーザーの利用が法令上認められる範囲でのみ行うものとします。</div>
          <div class="indent">
            　当該ユーザーが行う他のユーザーの個人情報の利用が違法となることが判明した場合には直ちに利用を停止するものとします。</div>
          <div class="indent">
            　当該ユーザーによる他のユーザーの個人情報の違法な利用により当社が損害を被った場合には、</div>
          <div class="indent">
            　当該ユーザーは当社に対して当社が被った損害の賠償義務を負うものとします。</div>
          <h3> 第 8 条 禁止事項</h3>
          <div class="indent">
            　ユーザーは当社が定める他のユーザーの利用に関するガイドラインを遵守するものとし、利用態様が当該ガイドライン上、許されない場合には、</div>
          <div class="indent">
            　当社は当該ユーザーに対して当該ガイドラインの遵守を請求する権利を有するものとします。</div>
          <div class="indent">
            　当該ユーザーによるガイドラインの違反があった場合には当社又は当該他のユーザーに対して損害賠償責任を負うものとします。</div>
          <h3>第 9 条 原作、漫画等の提供</h3>
          <p class="small-title">
            1. 提供手続</P>
          <div class="indent">
            　原作者、漫画家、編集者、アシスタント等漫画制作に関わる利用メンバーは、別途漫画共同制作に必要な契約をメンバー間で締結の上、</div>
          <div class="indent">
            　当社所定の手続に従い、漫画共同制作のための制作物の提供を行うものとします。提供者は、本規約に加え、</div>
          <div class="indent"> 
            　メンバー間で締結される漫画共同制作に関する契約に従って、本システムを利用して漫画制作を行うするものとします。</div>
          <div class="indent">
            　当社は、別途漫画共同制作のための契約書及び漫画共同制作のためのガイドラインを設け制作物の提供方法について基準を設け、</div>
          <div class="indent">
            　審査を行い、提供者の制作物がガイドラインで設定した基準に合致しない場合には、原作者・編集者において不承認できる等、</div>
          <div class="indent">
            　漫画共同制作に関する契約及び当該契約に基づく漫画共同制作のための補助となるルールを提供することができるものとします。</div>
          <p class="small-title">
            2. 提供禁止制作物</P>
          <div class="indent">
            　提供者は上述ガイドラインその他規約、契約で提供が禁止されている制作物を提供（以下「提供禁止制作物」といいます。）</div>
           <div class="indent">
            　に記載された制作物の提供を行ってはならないものとします。</div>
          <div class="indent">
            　提供禁止制作物に該当する制作物を提供した場合は、提供者の故意又は過失に関わらず、本規約違反行為とみなします。</div>
          <div class="indent">
            　当社又は他のユーザーが当該制作物の利用により損害を被った場合には、提供者は当社及び当該他のユーザーに対し損害賠償責任を負うものとします。</div>
          <p class="small-title">
            ３. 法令遵守</P>
          <div class="indent">
            　ユーザーは、制作物の提供にあたっては、著作権法、意匠法、商標法、特許法その他産業財産権及び知的財産権、不正競争防止法、</div>
          <div class="indent">
            　下請代金遅延等防止法、刑法、特定商取引に関する法律、不当景品類及び不当表示防止法、その他の法令を遵守しなければなりません。</div>
          <p class="small-title">
            ４. 提供に関する本規約違反</P>
          <div class="indent">
            　制作物の提供に関して、当社が法令、本規約又ユーザー間の契約に違反すると判断した場合、当社は、第5条に定める措置のほか、</div>
          <div class="indent">
            　ユーザー間の契約の停止・終了・解除その他当社又は他のユーザーが被る損害を回避する措置をとれるものとします。</div>
          <div class="indent">
            　本項に基づく措置によってユーザーに生じる損害について、当社の故意又は重過失に起因する場合を除き、当社は責任を負わないものとします。</div>
          <p class="small-title">
            5. 当社の免責</P>
          <div class="indent">
            　ユーザーの制作物提供によって、ユーザー及び第三者に生じる損害につき、当社の故意又は重過失に起因する場合を除き、</div>
          <div class="indent">
            　当社は責任を負わないものとします。</div>
          <h3>第 10条 本システムの中断・終了及び変更</h3>
          <p class="small-title">
            1. 本システムの中断</P>
          <div class="indent">
            　当社は、以下の各号のいずれかに該当する場合には、ユーザーに事前に通知することなく一時的に本システムの全部又は一部を中断する事ができるものとします。</div>
          <div class="indent">
            <p class="small">
              （１）サーバー、通信回線、その他の設備の故障、障害の発生又はその他の理由により本システムの提供ができなくなった場合
            </p>
            <p class="small">
              （２）定期的な又は緊急のシステム（サーバー、通信回線や電源、それらを収容する建築物などを含む）の保守、点検、修理、変更を行う場合
            </p>
            <p class="small">
              （３）火災、停電等により本システムの提供ができなくなった場合
            </p>
            <p class="small">
              （４）地震、噴火、洪水、津波等の天災により本システムの提供ができなくなった場合
            </p>
            <p class="small">
              （５）戦争、変乱、暴動、騒乱、労働争議等その他不可抗力により本システムの提供ができなくなった場合
            </p>
            <p class="small">
              （６）法令又はこれに基づく措置により本システムの提供ができなくなった場合
            </p>
            <p class="small">
              （７）その他運用上又は技術上、当社が必要と判断した場合
            </p>
          </div>
          <p class="small-title">
            2. 本システムの終了及び変更</P>
          <div class="indent">
            　当社は、適用法令に定める手続に従うことにより、任意の理由により、本システムの全部又は一部を終了及び変更できるものとします。</div>
          <div class="indent">
            　本システムを終了する場合においては、当社が適当と判断する方法で、可能な限り事前にユーザーにその旨を通知し、または公表するものとします。</div>
          <h3>第 11 条 知的財産権及びコンテンツ</h3>
          <p class="small-title">
            1. 知的財産権等の帰属</P>
          <div class="indent">
            　本システムを構成するすべての素材に関する権利は、当社又は当該権利を有する第三者に帰属しています。</div>
          <div class="indent">
            　ユーザーは、本システムのすべての素材に関して、一切の権利を取得することはないものとし、</div>
          <div class="indent">
            　権利者の許可なく、所有権、著作権を含む一切の知的財産権、肖像権、パブリシティー権等、</div>
          <div class="indent">
            　コンテンツ素材に関する権利を侵害する一切の行為をしてはならないものとします。</div>
          <div class="indent">
            　本規約に基づく本システムの利用の許諾は、本システムに関する当社又は当該権利を有する第三者の権利の使用許諾を意味するものではありません。</div>
          <p class="small-title">
            2.当社による利用漫画共同制作に関する制作物以外のユーザー情報（ユーザーにより投稿された写真、動画、情報等を含むがこれに限らない。）に関しては、
          <div class="indent">
            　本システムの宣伝、運営、研究開発及び発表等を目的として、当社及び当社の指定する者が自由に利用できるものとします。</div>
          <div class="indent">
            　但し、漫画共同制作のため制作物については利用規約・ガイドラインに従うものとします。</div>
          <div class="indent">
            　当該ユーザー情報のユーザーは上の目的のための当社及び本システムに関連する期間の制限のない無償利用について承諾したものとします。</div>
          </p>
          <p class="small-title">
            3. コンテンツに関する責任</P>
          <div class="indent">
            　ユーザー等が本システムに関連して発信又は掲載したコンテンツに関する一切の責任は、当該ユーザー等が負うものとし、</div>
          <div class="indent">
            　当社は、その内容、品質、正確性、信憑性、適法性、最新性、有用性等について、確認いたしません。また、当社は、それらに関して保証しないものとします。</div>
          <p class="small-title">
            4. コンテンツの内容等</P>
          <div class="indent">
            　ユーザー等は、他のユーザー等が発信又は掲載するコンテンツについて、その内容、品質、正確性、信憑性、適法性、最新性、有用性等を、</div>
          <div class="indent">
            　ユーザー等ご自身で判断する必要があります。当社は、ユーザー等及び第三者が当社のコンテンツを利用することにより生じる損害について、</div>
          <div class="indent">
            　当社の故意又は重過失に起因する場合を除き、責任を負わないものとします。</div>
          <p class="small-title">
            5. バックアップ</P>
          <div class="indent">
            　当社はコンテンツのバックアップを行う義務を負わないものとします。ユーザーは、コンテンツのバックアップが必要な場合には、</div>
          <div class="indent">  
            　自己の費用と責任でこれを行うものとします。</div>
          <p class="small-title">
            6. コンテンツの変更及び削除</P>
          <div class="indent">
            　当社は、ユーザーが本規約に違反又は本規約の精神に照らして不適切な行為を行ったと当社が判断した場合、当該ユーザーが掲載したあらゆるコンテンツを、</div>
          <div class="indent">
            　事前の通知なしに変更及び削除できるものとします。</div>
          <h3>第 12 条 ユーザーの責任及び接続環境等</h3>
          <p class="small-title">
            1. 必要な機器の準備等</P>
          <div class="indent">
            　本システムの提供を受けるために必要なコンピューター、スマートフォンその他の機器、ソフトウェア、通信回線その他の通信環境等は、</div>
          <div class="indent">
            　ユーザーの費用と責任において準備し維持するものとします。また、その機器、ソフトウェア、通信環境等の設置や操作についても、</div>
          <div class="indent">
            　ユーザーの費用と責任で行っていただく必要があります。</div>
          <div class="indent">
            　当社は、本システムがあらゆる機器等に適合することを保証するものではなく、機器等の準備、設置、操作に関し、</div>
          <div class="indent">
            　一切関与せず、ユーザーに対するサポートも行いません。</div>
          <p class="small-title">
            2. ネットワークの経由等</P>
          <div class="indent">
            　ユーザーは、本システムを利用する際に、種々のネットワークを経由する場合があることを理解し、接続しているネットワークや機器等によっては、</div>
          <div class="indent">
            　それらに接続したり、それらを通過するために、データや信号等の内容が変更される可能性があることを理解したうえで、本システムを利用するものとします。</div>
          <p class="small-title">
            3. 手続の成立</P>
          <div class="indent">
            　ユーザーがインターネット回線を通じて行う本システムへの入力、出品、購入、退会、その他の手続は、当社のサーバーに当該手続に関するデータが送信され、</div>
          <div class="indent">
            　当社のシステムに当該手続の内容が反映された時点をもって有効に成立するものとします。</div>
          <p class="small-title">
            4. トラブルの解決</P>
          <div class="indent">
            　本システムに関連してユーザー間又はユーザーと第三者間で発生したトラブル（本システムを将来利用するという前提の下で起こったトラブルを含みます。）
          </div>
          <div class="indent">
            　に関して、ユーザーは各自の費用及び責任で解決するものとします。</div>
          <div class="indent">
            　トラブルが生じた際には、当事者間で解決するものとし、当該トラブルにより当社が損害を被った場合は、</div>
          <div class="indent">
            　当事者は連帯して当該損害を賠償するものとします。</div>
          <p class="small-title">
            5. 第三者との紛争解決</P>
          <div class="indent">
            　ユーザーと第三者との間で、本システムに関連して、裁判、クレーム、請求等のあらゆるトラブルを含む紛争が生じた場合、</div>
          <div class="indent"> 
            　ユーザー各自の責任や費用で解決するものとし、</div>
          <div class="indent">
            　当社は、当該紛争に一切関与しません。当該紛争がユーザーの故意又は過失に起因して生じた場合には、</div>
          <div class="indent">
            　ユーザーは、当該紛争により当社に生じた損害を連帯して賠償するものとします。</div>
          <p class="small-title">
            6. 費用の負担</P>
          <div class="indent">
            　当社とユーザー間で紛争が生じた場合において、当該紛争がユーザーの故意又は過失に起因して生じた場合には、</div>
          <div class="indent">
            　ユーザーは当該紛争に関連して当社に発生した損害を賠償するものとします。</div>
          <h3>第13 条 非保証及び免責</h3>
          <p class="small-title">
            1.内容等に関する非保証</p>
          <div class="indent">
            　ユーザーは、本システムを各自の判断と責任で行うものとし、当社は、本システムの内容、品質及び水準並びに本システムの安定的な提供、</div>
          <div class="indent">
            　本システムの利用に伴う結果等（例：本システムを利用した結果マッチングされた他のユーザーが適切な能力を欠いていた場合、</div>
            <div class="indent">  
            　マッチングの組み合わせに不満が発生した場合、他のユーザーの脱退や業務の懈怠があった場合、</div>
          <div class="indent">
            　作成された漫画制作物自体が配信のための水準を満たさない場合等を含むがこれらに限らない。）については、保証しません。</div>
          <p class="small-title">
            2. 当社の免責</P>
          <div class="indent">
            　本システム提供における、不正確、不適切又は不明瞭な内容、表現、行為等により、ユーザー及び第三者に対して損害が生じた場合、</div>
          <div class="indent">
            　当社の故意又は重過失に起因する場合を除き、</div>
          <div class="indent">
            　当社は、当該損害について責任を負わないものとします。</div>
          <p class="small-title">
            3. 情報提供に関する非保証</P>
          <div class="indent">
            　当社は、本システムに関連して、ユーザー等に対して、適宜情報提供を行うことがありますが、当該情報の正確性や有用性を保証するものではありません。</div>
          <p class="small-title">
            4. コンピュータウィルス等に関する非保証</P>
          <div class="indent">
            　当社は、本システムに関連するコンテンツの中に、コンピュータウィルス等有害なものが含まれていないことに関して、表明保証又は保証をしません。</div>
          <div class="indent">
            　当社は、本システムに関連するコンテンツの中に、コンピュータウィルス等有害なものが含まれていたことにより生じた損害について、</div>
          <div class="indent">
            　ユーザー及び第三者に対して、当社の故意又は重過失に起因する場合を除き、責任を負わないものとします。</div>
          <h3>第 14 条 損害賠償</h3>
          <p class="small-title">
            1. ユーザーの責任</P>
          <div class="indent">
            　ユーザーが本規約に違反した場合、当該ユーザーが、当該違反により損害を受けたユーザー及び第三者に対する損害賠償責任を含む、</div>
          <div class="indent">
            　一切の責任を負うものとします。ユーザーがかかる違反行為を行ったことにより、</div>
          <div class="indent">
            　当社が損害を被った場合は、当該ユーザーその他関連当事者は連帯して当該損害を賠償するものとします。</div>
          <div class="indent">
            　本システムを利用する特定ユーザーの行った行為により他のユーザー又は第三者が損害を被った場合、</div>
          <div class="indent">
            　又はユーザー間の紛争若しくはユーザーと第三者との間の紛争が発生した場合においても、</div>
          <div class="indent">
            　当社はいかなるユーザーの使用者、発注者、共同行為者、名義の貸与者等の地位を有するものではなく、</div>
          <div class="indent">
            　当社は他のユーザー及び第三者に対し使用者責任、発注者責任、共同不法行為、その他いかなる責任をも負わないものとします。</div>
          <p class="small-title">
            2. 当社の免責</P>
          <div class="indent">
            　当社は、当社による本システムの提供の停止、終了、障害又は変更、ユーザー登録の取消・抹消コンテンツの削除又は消失､</div>
          <div class="indent">
            　本システムの利用によるデータの消失又は機器の故障その他本システムに関連してユーザーが被った損害につき、当社の故意又は重過失に起因する場合を除き、</div>
          <div class="indent">
            　賠償する責任を負わないものとします。</div>
          <p class="small-title">
            3. 当社の責任の範囲</P>
          <div class="indent">
            　当社がユーザーに対して損害賠償責任を負う場合においても、</div>
          <div class="indent">
            　当社の責任は、当社の債務不履行又は不法行為によりユーザーに生じた損害のうち現実に発生した、直接かつ通常の損害に限るものとし、</div>
          <div class="indent">
            　特別損害、逸失利益の損害を請求できないものとします。但し、当社の故意又は重過失に起因する場合を除きます。</div>
          <h3>第 15 条 一般条項</h3>
          <p class="small-title">
            1. 通知</P>
          <div class="indent">
            　本システムに関する当社からユーザーへの通知又は連絡は、当社が運営するウェブサイト又はアプリケーション内の適宜の場所への掲示その他、</div>
          <div class="indent">
            　当社が適当と判断する方法により行なうものとします。当社は、個々のユーザーに通知及び連絡をする必要があると判断した際、</div>
          <div class="indent">
            　登録された電子メールアドレス、住所又は電話番号に対し、メッセージング機能、電子メール、郵便、電話等を用いて通知及び連絡を行うことがあります。</div>
          <div class="indent">
            　ユーザーが当社に通知、連絡又は問い合わせ（本項において「問い合わせ等」といいます）をする必要が生じた場合、</div>
          <div class="indent">
            　本システムのお問い合わせフォームを利用するものとします。</div>
          <div class="indent">
            　ユーザーは当社に対する電話による連絡や当社への来訪を行うことはできないものとし、当社はこれに応じる義務を負わないものとします。</div>
          <div class="indent">
            　当社は、問い合わせ等があった場合、当社が定める方法により、ユーザーの本人確認を行うことができるものとします。</div>
          <div class="indent">
            　また、問い合わせに対する回答方法に関しては、当社が適切と考える回答方法を選択及び利用することができるものとします。</div>
          <div class="indent">
            　ユーザーは、問い合わせに対する当社の回答方法・回答形式を決めることはできないものとします</div>
          <p class="small-title">
            2. 譲渡禁止</P>
          <div class="indent">
            　ユーザーは、当社の書面による事前の承諾なく、本規約に基づく契約上の地位又は本規約に基づく権利若しくは義務につき、</div>
          <div class="indent">
            　第三者に対し、譲渡、移転、担保設定、貸与、その他の処分をすることはできません。</div>
          <p class="small-title">
            3. 事業譲渡等</P>
          <div class="indent">
            　当社が本システムに係る事業を第三者に譲渡する場合（事業譲渡、会社分割その他本システムの主体が移転する一切の場合を含みます。）には、</div>
          <div class="indent">
            　当社は、当該事業の譲渡に伴い、ユーザーの本規約に基づく契約上の地位、</div>
          <div class="indent">
            　本規約に基づく権利義務及びユーザー登録に伴い登録された情報その他の情報を当該事業の譲受人に譲渡することができるものとし、</div>
          <div class="indent">
            　ユーザーは、かかる譲渡につき予め承諾するものとします。</div>
          <p class="small-title">
            4. 分離可能性</P>
          <div class="indent">
            　本規約のいずれかの条項又はその一部が、法令等により無効と判断され又は取消された場合であっても、当該無効又は取消された部分を除く部分は、</div>
          <div class="indent">
            　当該部分の趣旨に反しない限り有効に存続するものとします。</div>
          <p class="small-title">
            5. 定めのない事項等</P>
          <div class="indent">
            　本規約に定めのない事項又は本規約の解釈に疑義が生じた場合には、当社及びユーザーは、協議の上解決を図るものとします。</div>
          <p class="small-title">
            6. 言語</P>
          <div class="indent">
            　本規約は、日本語を正文とします。本規約につき、他の言語で翻訳が作成された場合、日本語の正文のみが契約としての効力を有するものとます。</div>
          <div class="indent">
            　他の言語による翻訳は本規約を解釈する基準としての効力を有さないものとします。</div>
          <p class="small-title">
            7. 準拠法及び紛争解決機関</P>
          <div class="indent">
            　本規約は、日本法に基づき解釈されるものとし、ユーザーと当社の間で生じた紛争については、</div>
          <div class="indent">
            　事物管轄に従い東京簡易裁判所又は東京地方裁判所を第一審の専属的合意管轄裁判所とします。</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="confirmOne">閉じる</el-button>
      </span>
    </el-dialog>


    <el-dialog title="プライバシーポリシー" :visible.sync="dialogVisibletwo" width="72%">
      <div class="dialong-text">
        <div ref="dialogtop" style="max-height: 60vh; overflow-y: scroll; padding:0 20px" @scroll="handleScrollTwo">
          <h3>１　定義</h3>
          （１）「個人情報」とは、個人情報の保護に関する法律（平成15法律第57号。以下「個人情報保護法」といいます。）において個人情報として定義される情報をいいます。<br>
          （２）「漫画共同制作システム」とは、漫画制作を行おうとする複数の者が、共同で制作活動を行うために開発されたシステムであり、当社が「漫画共同制作システム」と呼ぶシステムをいいます。<br>
          （３）「利用者」とは「漫画共同制作システム」の利用者をいいます（利用登録を行った者のみならず、利用しようとする者を含みます）<br>
          <h3>２　個人情報の取得</h3>
          　当社は、個人情報を、「漫画共同制作システム」の利用登録手続、「漫画共同システム」の利用登録後の利用、<br>
          　「漫画共同システム」の利用者間での漫画共同制作に関する契約その他の合意に関連する手続時、<br>
          　「漫画共同制作システム」を通じた漫画作品の頒布、配信等を通じた金銭を各自に分配時その他、「漫画制作システム」の利用時、<br>
          　その他「漫画制作システム」の運用時、利用登録者から以下の個人情報を取得いたします。<br>
          （１） 氏名、生年月日、性別、職業、その他特定の個人を識別できる情報<br>
          （２） 電話番号、メールアドレス、パスワード、ニックネーム、アカウント情報<br>
          （３） クレジットカード情報、銀行口座やその他支払情報<br>
          （４）本システムを利用になる際の利用日時および回数、本システム利用時のシステム上の行動等、本システムの利用・閲覧に関連する情報<br>
          　　　（Cookie情報、アクセスログ、その他の利用状況に関する情報、利用の端末情報、<br>
          　　　　OS情報、位置情報、IPアドレス、ブラウザ情報、ブラウザ言語その他通信に関する情報）<br>
          （５） 利用者が本システムに入力・掲載・発信した投稿、写真、動画、コメント、取引メッセージ、評価その他の情報<br>
          （６） 利用者の制作等した原作・ストーリー・漫画関連情報<br>
          （７） その他漫画制作システムの利用時に利用者が入力した情報<br>
          （８） 特定個人情報（但し、法令上の必要がある場合に法令で許容される方法による）<br>
          <h3>３　個人情報の利用目的</h3>
          　当社は、個人情報を、以下の全ての目的で利用します（個人情報保護法その他の法令により利用が認められる場合には当該法令に従って利用を行います）。 <br>
          （１） 本システムにおける漫画制作・漫画制作契約・漫画配信の対価の分配のため<br>
          （２） 本システム関する各種事項の連絡や情報提供を行うため<br>
          （３）本システム上のサービスを提供するため<br>
          （４）本システムの利用登録その他の入力をする際に際に必要な情報入力を簡略化するため<br>
          （５）電子メール配信サービスのため<br>
          （６）本システムに関する利用確認のため<br>
          （７）本システムに関する請求・支払及び当該確認のため<br>
          （８）本システムの利用状況等の調査・分析のため<br>
          （９）本システムの変更・改良、本システムに代わる新システムの開発・移行のため<br>
          （１０）利用者の方の行動履歴等の情報を分析し本システムの向上に役立てるため<br>
          （１１）本システム内容を利用者に合わせてカスタマイズするため<br>
          （１２）本システムの問題点・満足度・改善提案等を調査するため<br>
          （１３）本システムにおける取得情報を統計的に処理した情報を公表し本システムの利用実績として公表するため<br>
          （１４）本システムや関連システムの研究開発のため<br>
          （１５）本システム上のサービスのご案内や通知をするため（マーケティング目的での案内や通知を含む）<br>
          （１６）本システムに関する調査の協力依頼や本システム関連イベントへの参加依頼をするため<br>
          （１７）当社の広告を利用者の方に合わせて配信・表示するため<br>
          （１８）本システム運営上のトラブルの解決のため<br>
          （１９）本システムに関する不正利用防止や安全性の確保のため<br>
          <h3>４　第三者提供</h3>
          　当社は、以下に定める場合、取得情報を第三者に提供します。また法令により第三者提供が認められる場合には当該法令に従い第三者提供を行うことがあります。<br>
          （１） 完成又は作成中の作品について配信先と配信可能性について協議を行う場合<br>
          （２） 本システムの利用者に対して漫画制作契約締結の検討時の提供を行う場合<br>
          （３） 本システムの利用者のうち漫画制作契約の締結を行った者の間で参加者の情報共有を行う場合<br>
          （４）以上のほか当社は、以下に定める場合、取得情報を第三者に提供します。<br>
          ・本システムにおけるサービスを提供するため当社が必要と判断した、本システムを通じた情報の提供の場合<br>
          ・当社が本システムの運営および提供において必要と判断した場合<br>
          ・法令等に基づき、裁判所、行政機関、監督官庁その他公的機関から個人情報の提供を求められた場合<br>
          ・当社及び本システムの利用者又は第三者の権利や利益等を守るために情報提供が必要と当社が判断した場合<br>
          ・クレジットカード発行会社が行う不正利用検知および不正利用防止のため、利用者の方が本システムを利用するにあたってご登録いただいた情報、<br>
          　本システム利用の状況等を、利用者の方の利用されているカード発行会社に提供する場合<br>
          ・その他、 弊社グループが取得情報の提供が必要と判断した場合に、お客様の同意を得た上で、取得情報を提供することがあります<br>
          <h3>5　個人情報の管理（安全管理のために講じた措置）</h3>
          　 当社は、個人情報の漏えい、滅失又は毀損等を防止するため、必要かつ適切な措置を講じます。また、個人情報の安全な管理が図られるよう、<br>
          　 当社及び「漫画制作システム」の運営体制を通じて必要かつ適切な管理を行い、個人情報の取扱いの全部または一部を委託する場合には、<br>
          　 委託先に対して必要かつ適切な監督を行います。<br>
          <h3>６　Cookieについて</h3>
          　CookieについてはCookieポリシーをご確認及びご承認ください。<br>
          <h3>７　個人情報の利用目的の通知、開示、訂正、利用停止等のご請求</h3>
          　 当社は、保有する個人情報について、ご本人から以下のご請求があった場合には、ご本人からの請求であることを確認のうえ、個人情報保護法の定めに従って、<br>
          　 対応します。<br>
          （１）利用目的の通知<br>
          （２）開示、訂正・追加・削除<br>
          （３）利用停止・消去、第三者への提供の停止<br>
          <h3>８　お問い合せ窓口</h3>
          　本プライバシーポリシー又は個人情報の取扱いに関するお問い合せは、以下にて受け付けます。<br>
          　住所：群馬県前橋市広瀬町１丁目３０番地６ウェルパークⅡ３０２<br>
          　問い合わせ：
          （なお、受付時間は、平日午前10時から午後21時までとさせていただきます。）
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="confirmTwo">閉じる</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import service from "@/service";
import utils from "@/assets/js/utils";
export default {
  name: "sign",
  data() {
    //確認用パスワード
    const checkPassWord = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("パスワードを入力して下さい"));
      }
      setTimeout(() => {
        if (value.length < 6) {
          return callback(new Error("パスワードは6位以上でお願いします"));
        } else {
          callback();
        }
      }, 500);
    };
    //確認用パスワード入力
    const checkPass = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("パスワードを確認して下さい"));
      } else if (value !== this.loginInfo.password) {
        return callback(new Error("二回入力したパスワードは不一致しています"));
      } else {
        callback();
      }
    };
    const checkEmail = (rule, value, callback) => {
      if (value !== this.loginInfo.Email) {
        return callback(new Error("二回入力したパスワードは不一致しています"));
      } else {
        callback();
      }
    };
    // ニックネーム確認
    const checkUsernickname = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("ニックネームを入力して下さい"));
      } else {
        callback();
      }
    };
    return {
      LS: {
        'color': '#000',
        'font-weight': '700',
        'text-align': 'right',
        'width': '180px',
        'word-break': 'keep-all'

      },
      // 抽屉
      drawer: false,
      twoScroll: true,
      isTwoBook: false,
      oneScroll: true,
      isOneBook: false,
      loginInfo: {
        id: "",
        Email: "",
        password: "",
        checkPass: "",
        checkEmail: ''
      },
      userInfo: {
        nickname: "",
        iconname: "",
        imageUrl: "",
        iconUrl: "",
        loginAttr: "",
        userPhoto: { url: require("../../assets/img/banner/1.png") },
      },
      hopeInfo: {
        type: [],
        checked: [],
      },
      media: {
        type: [],
        checked: [],
      },
      classification: {
        classA: {
          type: [],
          checked: [],
        },
        // classB: {
        // 	type: [],
        // 	checked: []
        // },
      },
      isCreation: {
        type: [],
      },
      bind: [
        {
          id: 0,
          name: "",
        },
        {
          id: 1,
          name: "",
        },
      ],
      getReward: [
        {
          id: 0,
          name: "",
        },
        {
          id: 1,
          name: "",
        },
      ],
      // quotation: "",
      selfIntroduction: "",
      favoritePainter: "",
      cartoon: "",
      point: "",

      perSituation: {
        method: "",
      },
      exper: {
        type: [],
      },
      achievObj: [
        {
          selfProdu: [
            { value: "1", label: "商業" },
            { value: "2", label: "自主制作" },
          ],
          color: [
            { value: "1", label: "カラー" },
            { value: "2", label: "白黒" },
          ],
          isSerial: [
            { value: "1", label: "読切" },
            { value: "2", label: "連載" },
          ],
          serialSize: [
            { value: "1", label: "縦スク" },
            { value: "2", label: "見開き" },
          ],
          selfProduVal: "",
          colorVal: "",
          isSerialVal: "",
          serialSizeVal: "",
          workName: "",
          author: "",
          desc: "",
        },
      ],
      resUrl: [
        {
          id: 0,
          name: "",
        },
      ],
      makeAndEdit: {
        make: "",
        edit: "",
        publish: "",
      },
      language: [
        {
          lang: [],
          fromLanguageVal: "",
          toLanguageVal: "",
        },
      ],
      saveLang: [],
      notice: false,
      num: 0,
      token: "",
      isShow: true,
      rules: {
        Email: [
          { required: true, validator: utils.checkUserEmail, trigger: "blur" },
        ],
        password: [
          { required: true, validator: checkPassWord, trigger: "blur" },
        ],
        checkPass: [{ required: true, validator: checkPass, trigger: "blur" }],
        checkEmail: [{ required: true, validator: checkEmail, trigger: "blur" }]
      },
      userRules: {
        nickname: [
          { required: true, validator: checkUsernickname, trigger: "blur" },
        ],
        loginAttr: [
          { required: true, message: "请选择活动资源", trigger: "change" },
        ],
      },
      hopeRules: {
        checked: [
          {
            type: "array",
            required: true,
            message: "少なくとも1つ選択して下さい",
            trigger: "change",
          },
        ],
      },
      mediaRules: {
        checked: [
          {
            type: "array",
            required: true,
            message: "少なくとも1つ選択して下さい",
            trigger: "change",
          },
        ],
      },
      classifiA: {
        checked: [
          {
            type: "array",
            required: true,
            message: "少なくとも1つ選択して下さい",
            trigger: "change",
          },
        ],
      },
      classifiB: {
        checked: [
          {
            type: "array",
            required: true,
            message: "少なくとも1つ選択して下さい",
            trigger: "change",
          },
        ],
      },
      isCreationRules: {
        type: [
          {
            type: "array",
            required: true,
            message: "少なくとも1つ選択して下さい",
            trigger: "change",
          },
        ],
      },
      methodRules: {
        method_: [
          { required: true, message: "作画方法を選択して下さい", trigger: "change" },
        ],
      },
      paramsStatus: false,
      dialogVisibleOne: false,
      dialogVisibletwo: false
    };
  },
  computed: {
    islang() {
      let status = this.hopeInfo.checked.some(item => item == 14)
      return status
    },
  },

  mounted() {
    this.getUserInfo();
  },
  filters: {
    filterLoginAtt(newVal) {
      let val = newVal == "1" ? "クリエイター" : "編集者";
      return val;
    },
    filterIsCreation(newVal) {
      let val = [];
      val.push(newVal);
      const set = new Set(val);
      return val;
    },
  },
  methods: {
    // chouti
    filterHopeType(id) {
      let label = "";
      this.hopeInfo.type.forEach((item) => {
        if (id == item.id) {
          label = item.position;
        }
      });
      return label;
    },
    getLanguage(id) {
      let text = ""
      this.saveLang.forEach(item => {
        if (id == item.id) {
          text = item.language
        }
      })
      return text
    },
    filterMediaType(id) {

      let label = "";
      this.media.type.forEach((item) => {
        if (id == item.id) label = item.media;
      });
      return label;
    },
    filterClassAType(id) {
      let label = "";
      this.classification.classA.type.forEach((item) => {
        if (id == item.id) label = item.typeb;
      });
      return label;
    },
    filterBandAType(id) {
      let lable = "";
      this.bind.forEach((item) => {
        if (id == item.id + 1) lable = item.name;
      });
      return lable;
    },

    ///////
    goIndex() {
      this.$router.push("/");
    },
    //获取注册数据
    getUserInfo() {
      service.home.getUserInfo().then((res) => {
        if (res.status == 200 && JSON.stringify(res.data) != "{}") {
          let data = res.data;
          this.hopeInfo.type = data.positions;
          this.media.type = data.medias;
          this.classification.classA.type = data.typebigs;
          this.language[0].lang = data.language;
          this.saveLang = data.language;
          this.token = res._token;
        }
      });
    },
    upLoadPic(e) {
      console.log(e);
      if (e.file) {
        const file = e.file;
        let formData = new FormData();
        formData.append("file", file);
        service.home
          .userUpload(formData)
          .then((res) => {
            this.nickname = res.data.filename;
            this.userInfo.imageUrl = `/userupload/${res.data.filename}`;
            this.$message.success({ message: 'アイコンがアップロードされました', duration: 10000 });
          })
          .catch((err) => {
            console.log(err);
            this.$message.error({ message: "アイコンのアップロードに失敗しました", duration: 10000 });
          });
      }
    },
    upLoadIcon(e) {
      console.log(e);
      if (e.file) {
        const file = e.file;
        let formData = new FormData();
        formData.append("file", file);
        service.home
          .userUpload(formData)
          .then((res) => {
            this.userInfo.iconUrl = `/userupload/${res.data.filename}`;
            this.iconname = res.data.filename;
            this.$message.success({ message: "ヘッダーがアップロードされました", durantion: 10000 });
          })
          .catch(() => {
            this.$message.error({ message: "ヘッダーのアップロードに失敗しました", duration: 10000 });
          });
      }
    },
    filterLang() {
      this.hopeInfo.checked.indexOf(14) > -1
        ? (this.isShow = true)
        : (this.isShow = false);
    },

    addInput() {
      utils.addItem("input", this.bind);
    },
    deleteInput(index) {
      utils.deleteItem(this.bind, index);
    },
    addRewardInput() {
      utils.addItem("input", this.getReward);
    },
    deleteRewardInput(index) {
      utils.deleteItem(this.getReward, index);
    },
    addResSel() {
      utils.addItem("select", this.achievObj);
    },
    deleteResSel(index) {
      utils.deleteItem(this.achievObj, index);
    },
    addUrlInput() {
      utils.addItem("input", this.resUrl);
    },
    deleteUrlInput(index) {
      utils.deleteItem(this.resUrl, index);
    },
    addLangSel() {
      this.language.push({
        lang: this.saveLang,
        fromLanguageVal: "",
        toLanguageVal: "",
      });
    },
    deleteLang(index) {
      utils.deleteItem(this.language, index);
    },
    clickRhxz() {
      this.dialogVisibleOne = true
      this.$nextTick(() => {
        this.$refs.dialogtopOne.scrollTop = 0

      })
    },
    clickRhjs() {
      this.dialogVisibletwo = true
      this.$nextTick(() => {
        this.$refs.dialogtop.scrollTop = 0

      })


    },
    handleScrollTwo(e) {
      const { scrollTop, clientHeight, scrollHeight } = e.target
      let top = Math.ceil(scrollTop)
      if (top + clientHeight >= scrollHeight) {
      }
    },
    confirmTwo() {
      this.dialogVisibletwo = false;
    },
    handleScrollOne(e) {
      const { scrollTop, clientHeight, scrollHeight } = e.target
      let top = Math.ceil(scrollTop)
      if (top + clientHeight >= scrollHeight) {
      }
    },
    confirmOne() {
      this.dialogVisibleOne = false;
    },
    submit() {
      // 抽屉
      this.drawer = true;

      let them = "";
      let getReward = "";
      let resUrl = "";
      if (this.bind.length) {
        them = this.bind
          .map((item) => {
            return item.name;
          })
          .join("#");
      }
      if (this.getReward.length) {
        getReward = this.getReward
          .map((item) => {
            return item.name;
          })
          .join("#");
      }
      if (this.resUrl.length) {
        resUrl = this.resUrl
          .map((item) => {
            return item.name;
          })
          .join("#");
      }

      let lang = [];
      if (this.language.length) {
        for (let i = 0; i < this.language.length; i++) {
          console.log(
            this.language[i].fromLanguageVal,
            ";",
            this.language[i].toLanguageVal
          );
          lang.push(
            this.language[i].fromLanguageVal +
            ";" +
            this.language[i].toLanguageVal
          );
        }
      }
      let makeRes = [];
      if (this.achievObj.length) {
        for (let i = 0; i < this.achievObj.length; i++) {
          makeRes.push(
            this.achievObj[i].selfProduVal +
            ";" +
            this.achievObj[i].colorVal +
            ";" +
            this.achievObj[i].isSerialVal +
            ";" +
            this.achievObj[i].serialSizeVal +
            ";" +
            this.achievObj[i].workName +
            ";" +
            this.achievObj[i].author +
            ";" +
            this.achievObj[i].desc
          );
        }
      }
      this.$refs.myForm.validate((valid) => {
        if (valid) {
          if (this.userInfo.loginAttr == "1") {
            if (
              this.hopeInfo.checked.length &&
              this.media.checked.length &&
              this.classification.classA.checked.length &&
              this.isCreation.type.length &&
              this.userInfo.nickname
            ) {
              if (this.notice) {
                let params = {
                  username: this.loginInfo.Email, //用户名
                  password: this.loginInfo.password, //密码
                  icon: this.iconname, //头像
                  pic: this.nickname, //图标
                  loginattr: this.userInfo.loginAttr, //登录属性
                  positions: this.hopeInfo.checked.join(","), // x希望参加的制作
                  media: this.media.checked.join(","), //媒体
                  typebs: this.classification.classA.checked.join(","), //大分类
                  creativecap: this.isCreation.type.join(","), // 创作 or 随想
                  experience: this.exper.type.join(",") || "", // 作画经验
                  adscartoon: this.makeAndEdit.make, // 制作依赖
                  nickname: this.userInfo.nickname, //昵称
                  theme: them, //感兴趣的题材
                  //quotation: this.quotation, //引语
                  introduction: this.selfIntroduction, //自我介绍
                  cartoon: this.cartoon, //喜欢的漫画
                  cartooner: this.favoritePainter, //喜欢的漫画家
                  cute: this.point, //萌点
                  drawmethod: this.perSituation.method_, //作画方法
                  reward: getReward, //得到的奖励
                  achievements: makeRes.join("#"), //制作成果
                  introduce: resUrl, //外部URL
                  publish: this.makeAndEdit.publish, //海外出版
                  translate: lang.join("#"), //翻译
                };
                service.home.getToken().then((res) => {
                  if (res.status == 200) {
                    params["_token"] = res._token;
                    service.home
                      .userSign(params)
                      .then((res) => {
                        if (res.status == 200) {
                          this.$message.success(
                            {
                              message: "仮登録完了。メールでご案内するアドレス (URL) にアクセスし、会員登録が完了します",
                              duration: 10000,
                            }

                          );
                          this.$router.push("/login")
                        } else {
                          this.$message.error('邮箱已注册！')
                        }
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  }
                });
              } else {
                this.$message.error({ message: "入会規則をご確認の上、同意して下さい", duration: 10000 });
              }
            } else {
              this.paramsStatus = false
              // this.$message.error({message: "必須項目の入力をチェックして下さい", duration:100000});
            }
          } else if (this.userInfo.loginAttr == "2") {
            if (
              this.classification.classA.checked.length &&
              this.isCreation.type.length &&
              this.userInfo.nickname
            ) {
              this.paramsStatus = true
              if (this.notice) {
                let params = {
                  username: this.loginInfo.Email, //用户名
                  password: this.loginInfo.password, //密码
                  icon: this.iconname, //头像
                  pic: this.nickname, //图标
                  loginattr: this.userInfo.loginAttr, //登录属性
                  typebs: this.classification.classA.checked.join(","), //大分类
                  creativecap: this.isCreation.type.join(","), // 创作 or 随想
                  experience: this.exper.type.join(",") || "", // 作画经验
                  adscartoon: this.makeAndEdit.make, // 制作依赖
                  nickname: this.userInfo.nickname, //昵称
                  theme: them, //感兴趣的题材
                  media: this.media.checked.join(","), //媒体
                  // quotation: this.quotation, //引语
                  introduction: this.selfIntroduction, //自我介绍
                  cartoon: this.cartoon, //喜欢的漫画
                  cartooner: this.favoritePainter, //喜欢的漫画家
                  cute: this.point, //萌点
                  drawmethod: this.makeAndEdit.edit, //所属编辑部
                  achievements: makeRes.join("#"), //制作成果
                  publish: this.makeAndEdit.publish, //海外出版
                  _token: this.token,
                };
                service.home.getToken().then((res) => {
                  if (res.status == 200) {
                    params["_token"] = res._token;
                    service.home
                      .userSign(params)
                      .then((res) => {
                        if (res.status == 200) {
                          this.$message.success("仮登録完了。メールでご案内するアドレス (URL) にアクセスし、会員登録が完了します");
                          this.$router.push("/login")
                        } else {
                          this.$message.error("邮箱已注册！")
                        }
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  }
                });
              } else {
                this.$message.error("入会規則をご確認の上、同意して下さい");
              }
            } else {
              this.paramsStatus = false
              // this.$message.error("必須項目の入力をチェックして下さい");
            }
          }
          this.paramsStatus = true
        } else {
          this.this.paramsStatus = false
          // this.$message.error("必須項目の入力をチェックして下さい");
        }
      });
    },
    handleClose(done) {
      this.$confirm("この画面を閉じます？")
        .then((_) => {
          done();
        })
        .catch((_) => { });
    },
    isOpenDrawe() {
      this.$refs.myForm.validate((valid) => {
        if (valid) {
          if (this.userInfo.loginAttr === '1') {
            if (
              this.hopeInfo.checked.length &&
              this.media.checked.length &&
              this.classification.classA.checked.length &&
              this.isCreation.type.length &&
              this.userInfo.nickname &&
              this.perSituation.method_ &&
              this.notice
            ) {
              this.drawer = true
            } else {
              if (!this.notice) {
                this.$message.error({ message: "入会規則をご確認の上、同意して下さい", duration: 10000 });
              } else {
                this.$message.error({ message: "必須項目の入力をチェックして下さい", duration: 10000 });
              }
            }
          } else {
            if (
              this.media.checked.length &&
              this.classification.classA.checked.length &&
              this.isCreation.type.length &&
              this.userInfo.nickname &&
              this.notice
            ) {
              this.drawer = true
            } else {
              if (!this.notice) {
                this.$message.error({ message: "入会規則をご確認の上、同意して下さい", duration: 10000 });
              } else {
                this.$message.error({ message: "必須項目の入力をチェックして下さい", duration: 10000 });
              }
            }
          }
        } else {
          this.$message.error({ message: "必須項目の入力をチェックして下さい", duration: 10000 });
        }
      })




      // if(this.paramsStatus && this.notice) {
      //   this.drawer = true
      // } else {
      //   if(!this.notice) {
      //      this.$message.error( {message: "入会規則をご確認の上、同意して下さい", duration:10000});
      //   } else {
      //      this.$message.error( {message:"必須項目の入力をチェックして下さい", duration:10000 });
      //   }
      // }
    }
  },
};
</script>
<style>
.my-label {
  background: #ddd !important;
}
</style>
<style lang="scss" scoped>
::v-deep.el-descriptions-item__label.is-bordered-label {
  background-color: red !important;
}

.header {
  width: 100%;
  background-color: #fff;
  position: sticky;
  top: 0px;
  font-size: 17px;
  z-index: 200;

  .header-box {
    display: flex;
    height: 55px;
    align-items: center;
    width: 55%;
    margin: 0 auto;

    >div:first-child {
      flex: 1;
      font-size: 17px;
      color: #333;
      font-weight: 500;
    }

    >div:last-child {
      span {
        color: #0051d9;
        font-size: 17px;
        margin-left: 20px;
        cursor: pointer;
      }
    }
  }
}

.sign-main {
  width: 100%;
  background-color: #f4f4f4;
  padding: 20px 0;

  .same-style {
    width: 55%;
    margin: 0 auto;
    background: #fff;
    padding: 0 20px;

    h4 {
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      color: #333;
      font-weight: 600;
      letter-spacing: 2px;
    }
  }

  .login-info {
    .form-content {
      padding: 20px 0;
      width: 45%;

      ::v-deep .el-form-item__label {
        font-size: 14px;
        color: #666;
        letter-spacing: 2px;
      }
    }
  }

  .user-info {
    margin-top: 20px;

    .userInfo {
      width: 100%;
      padding: 20px 0;

      .form-content {
        width: 45%;

        ::v-deep .avatar-uploader .el-upload {
          border: 1px solid #999;
          border-radius: 4px;
          cursor: pointer;
          position: relative;
          overflow: hidden;
          width: 60px;
          height: 60px;
          background: #f4f4f4;
        }

        .avatar {
          width: 178px;
          height: 178px;
          display: block;
        }

        .avatar-uploader-icon {
          font-size: 20px;
          color: #8c939d;
          line-height: 60px;
        }
      }

      .hope-make {
        ::v-deep .el-checkbox {
          width: 160px;
          margin-right: 60px;
        }
      }

      .classifi {
        max-height: 250px;
        overflow-y: auto;

        ::v-deep .el-checkbox {
          width: 160px;
          margin-right: 60px;
        }
      }

      .classifi::-webkit-scrollbar {
        width: 5px;
      }

      .classifi::-webkit-scrollbar-track {
        background-color: #f4f8fb;
        border-radius: 10px;
      }

      .classifi::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border-radius: 10px;
      }

      .interested-theme {
        margin-top: 20px;

        .title {
          display: flex;
          align-items: center;

          p {
            margin-left: 20px;
            cursor: pointer;

            span:first-child {
              display: inline-block;
              width: 15px;
              height: 15px;
              border-radius: 2px;
              background-color: #0051d9;
              line-height: 15px;
              text-align: center;
              margin-right: 5px;

              i {
                color: #fff;
                font-size: 12px;
              }
            }

            span:last-child {
              color: #0051d9;
            }
          }
        }

        .ipt-box {
          width: calc(100% - 100px);
        }

        ul {
          li {
            display: flex;
            align-items: center;
            margin: 10px 0;
            width: 100%;

            div:first-child {
              flex: 1;
              // width: 90%;
            }

            >div:last-child {
              width: 100px;
              text-align: center;
              cursor: pointer;
              color: #f03747;
              visibility: hidden;
            }
          }

          li:hover {
            >div:last-child {
              visibility: visible;
            }
          }
        }

        ol {
          li {
            margin-top: 20px;
            display: flex;
            align-items: center;

            >div:first-child {
              text-align: center;
              width: calc(100% - 100px);

              ::v-deep .el-select {
                width: 100%;
              }

              label {
                color: #606266;
                line-height: 36px;
              }
            }

            >div:last-child {
              width: 100px;
              text-align: center;
              cursor: pointer;
              color: #f03747;
              visibility: hidden;
            }
          }

          li:hover {

            //border: 1px solid #BFBFBF;
            >div:last-child {
              visibility: visible;
            }
          }
        }
      }

      .personal-situation {
        margin-top: 20px;
      }

      .achievements {
        .title {
          display: flex;
          align-items: center;

          p {
            margin-left: 20px;
            cursor: pointer;

            span:first-child {
              display: inline-block;
              width: 15px;
              height: 15px;
              border-radius: 2px;
              background-color: #0051d9;
              line-height: 15px;
              text-align: center;
              margin-right: 5px;

              i {
                color: #fff;
                font-size: 12px;
              }
            }

            span:last-child {
              color: #0051d9;
            }
          }
        }

        >div {
          ul {
            width: 100%;

            li {
              width: 100%;
              display: flex;
              align-items: center;
              padding: 10px;
              border: 1px solid transparent;

              .select-box {
                width: calc(100% - 100px);

                ::v-deep .el-select {
                  width: 100%;
                }

                >div {
                  margin-top: 10px;
                }
              }

              .remove {
                width: 100px;
                text-align: center;
                cursor: pointer;
                color: #f03747;
                visibility: hidden;
              }
            }

            li:hover {
              border: 1px solid #bfbfbf;

              .remove {
                visibility: visible;
              }
            }
          }
        }
      }
    }
  }

  .notice {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    font-size: 16px;
    font-weight: bold;

    /deep/.el-link {
      font-size: 16px;
      font-weight: bold;

    }

    /deep/ .el-checkbox__label {
      font-size: 16px;
      font-weight: bold;
      margin-right: 10px;


    }

    span {
      color: #606266;
      margin: 0 10px;
    }
  }

  .submit {
    display: flex;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 40px;

    span {
      display: block;
      width: 200px;
      height: 50px;
      line-height: 50px;
      border-radius: 8px;
      background: #f03747;
      color: #fff;
      text-align: center;
      cursor: pointer;
    }
  }

  .achive_ {
    span {
      padding: 0 5px;
    }
  }
}

::v-deep.my-label {
  background-color: red;
}

// 注册信息
::v-deep.registerInfo {
  padding: 20px;

  ul {
    width: 100%;

    li {
      span {
        margin-left: 20px;
        font-size: 16px;
      }

      height: 32px;

      h4 {
        font-size: 18px;
      }

      div {
        padding-left: 20px;
      }
    }
  }
}

.dialong-text {
  line-height: 22px;

  h3 {
    padding: 10px 0;
  }

  .small-title {
    padding: 7px 0;
  }

  .small {
    padding: 4px 0;
  }

  .indent {
    padding-left: 10px;
  }
}
</style>
